/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, Fullmap, FullmapWrap, ColumnWrapper, Title, FullmapCover, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Kontakt"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column name={"l8lca1wp37p"} style={{"paddingTop":0,"paddingBottom":2}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Fullmap name={"ue2gowa72tj"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="--center" name={"brk06ppzac"} style={{"paddingTop":120,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":0}}>
              
              <Title className="title-box title-box--center" content={"<span style=\"text-decoration-line: underline;\">KONTAKT</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" name={"0d55y4on8dtg"} style={{"paddingTop":18,"paddingBottom":16}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column name={"o9f42i4dizc"} style={{"paddingTop":0,"paddingBottom":0}} layout={"l5"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

          <ColumnWrap className="column__flex el--2 flex--stretch" style={{"marginBottom":40}} columns={"2"}>
            
            <ColumnWrapper >
              
              <FullmapCover zoom={"15"} place={"Kolovratská 1476"} style={{"overflow":"hidden","borderRadius":"6px","paddingBottom":24}}>
              </FullmapCover>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--center fs--22" content={"<span style=\"font-weight: bold;\"><br>Vodohospodářská laboratoř Říčany s.r.o.\n<br>Kolovratská 1476, Říčany <br>(areál 1. SčV)<br><br>mobil:+420 602 363 180\n<br>tel: + 420 323 602 539\n<br>laboratorkopecna@seznam.cz<br><br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap name={"3w8gc9wdg1v"} style={{"backgroundColor":"rgba(224, 224, 224, 1)"}}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column name={"p80xes668n"} style={{"paddingTop":0,"paddingBottom":0}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":26,"paddingTop":0,"paddingLeft":103,"paddingRight":24,"paddingBottom":0,"backgroundColor":"rgba(160, 160, 160, 1)"}} name={"o-nas"}>
          
          <ColumnWrap className="column__flex el--2 mb--10 mt--10" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":606}} content={"<br><span style=\"color: var(--black); font-weight: bold;\">Vodohospodářská laboratoř Říčany, s. r. o.\n<br>Kolovratská 1476,251 01 Říčany\n<br>tel.: 323602539\nmobil: 602363180\n<br>e-mail: laboratorkopecna@seznam.cz\n<br><br>&nbsp;IČO 28393601\nDIČ CZ28393601</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":495}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold; text-decoration-line: underline;\">Příjem vzorků vody:\n</span><br>Pondělí a úterý: 8.00 – 15.30 hod.<br>Středa: 8.00 - 11.00 hod.\n<br><br>&nbsp;<span style=\"text-decoration-line: underline; font-weight: bold;\">Pracovní doba laboratoře:\n</span><br>Pondělí až čtvrtek: 8.00 - 15.30 hod. (po dohodě do 18 hod.)<br>Pátek: 8.00 - 14.00 hod.\n<br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}